import React, { useState, useEffect } from "react";

import config from "../config";
import BasicButton from "../sharedcomponents/BasicButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [callbackUrl, setCallbackUrl] = React.useState(null);

  const handleOutsideMenuClick = () => {
    if (menuOpen) setMenuOpen(false);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("header").style.paddingTop = "5px";
      document.getElementById("header").style.paddingBottom = "5px";
      document.getElementById("logo").style.transform = "scale(0.9)";
      document.getElementById("header").style.boxShadow = "0 4px 10px 0 rgb(0 0 0 / 5%)";
    } else {
      document.getElementById("header").style.paddingTop = "8px";
      document.getElementById("header").style.paddingBottom = "8px";
      document.getElementById("logo").style.transform = "scale(1)";
      document.getElementById("header").style.boxShadow = "0 0 0 0 rgb(0 0 0 / 5%)";
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (props.callbackurl) setCallbackUrl(props.callbackurl);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <nav
      id="header"
      style={{ WebkitTransition: "all .25s ease", transition: "all .25s ease" }}
      className="flex bg-blue-header p-2 mt-0 fixed w-full z-50 top-0"
      onClick={handleOutsideMenuClick}
    >
      <div className="container mx-auto flex  flex-grow  max-w-none space-between py-1">
        <div className="flex w-full text-gray-500 font-extrabold space-between">
          <a className=" ml-8" href={config.mainPage + "/"}>
            <img
              id="logo"
              src="/assets/Automate-video-logo-with-symbol.png"
              style={{
                maxHeight: 37,
                WebkitTransform: "scale(.85)",
                transform: "scale(.85)",
                WebkitTransition: "-webkit-transform .25s ease",
                transition: "-webkit-transform .25s ease",
                transition: "transform .25s ease",
                transition: "transform .25s ease,-webkit-transform .25s ease",
              }}
            />
          </a>
          {!menuOpen ? (
            <button className="flex ml-auto lg:hidden mr-2 justify-end" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faBars} width={25} color="gray" />
            </button>
          ) : null}
        </div>
        <div className="flex ml-4 flex-grow justify-end  md:justify-end hidden lg:flex">
          <ul className="list-reset flex justify-between flex-1 md:flex-none items-center ">
            <li className="mr-3">
              <BasicButton headerStyle gray name="All videos" url={config.mainPage + "/directory/all"} />
            </li>

            <li className="mr-3">
              <BasicButton headerStyle gray name="Browse" url={config.mainPage + "/browse"} />
            </li>
            <li className="mr-3">
              <BasicButton headerStyle gray name="Pricing" url={config.mainPage + "/#pricing"} />
            </li>
            {callbackUrl ? (
              <li className="mr-3">
                <BasicButton
                  headerStyle
                  gray
                  name="Login"
                  url={config.mainPage + "/login?mode=login&callbackUrl=" + callbackUrl}
                />
              </li>
            ) : (
              <li className="mr-3">
                <BasicButton headerStyle gray name="Login" url={config.mainPage + "/login?mode=login"} />
              </li>
            )}
            <li className="mr-3">
              <BasicButton headerStyle gray name="Sign Up" url={config.mainPage + "/login?mode=register"} />
            </li>
            <li className="mr-3">
              <BasicButton headerStyle gray name="What is automate.video?" url={"https://www.automate.video/home"} />
            </li>
          </ul>
        </div>
        {menuOpen ? (
          <div className="flex flex-col bg-white py-2  -ml-2 px-4">
            <a
              href={"/directory/all"}
              className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
            >
              All videos
            </a>
            <a
              href={"/browse"}
              className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
            >
              Browse videos
            </a>
            <a
              href={config.mainPage + "/#pricing"}
              className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
            >
              Pricing
            </a>
            {callbackUrl ? (
              <a
                href={config.mainPage + "/login?mode=login&callbackUrl=" + callbackUrl}
                className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
              >
                Login
              </a>
            ) : (
              <a
                href={config.mainPage + "/login?mode=login"}
                className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
              >
                Login
              </a>
            )}
            <a
              href={config.mainPage + "/login?mode=register"}
              className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
            >
              Sign up
            </a>
            <a
              href={"https://www.automate.video/home"}
              className="mr-6 mb-2 font-bold text-gray-500 hover:text-orange text-base md:text-lg lg:text-xl"
            >
              What is automate.video?
            </a>
          </div>
        ) : null}
      </div>
    </nav>
  );
}
