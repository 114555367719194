export default function Footer() {
  let imageStyle = {
    margin: "auto",
    display: "block",
    width: "100%",
    objectFit: "cover",
    height: "45px",
    width: "80px",
  };
  return (
    <footer className="bg-white mt-10 pb-10 font-normal text-gray-500 text-left absolute w-full min-h-96 ">
      <div className="flex flex-col md:flex-row mx-6 text-sm">
        <div className="flex-1 mx-6 my-6">
          <h3 className="text-xl font-bold mb-6 ">Use cases</h3>
          <ul>
            <li className="leading-8">
              <a href="">Corporate Training</a>
            </li>
            <li className="leading-8">
              <a href="">Presentations and reports</a>
            </li>
            <li className="leading-8">
              <a href="">E-learning materials</a>
            </li>
            <li className="leading-8">
              <a href="">Course videos</a>
            </li>
            <li className="leading-8">
              <a href="">Promotional videos</a>
            </li>
          </ul>
        </div>

        <div className="flex-1 mx-6 my-6">
          <h3 className="text-xl font-bold mb-6 ">Product</h3>
          <ul>
            <li className="leading-8">
              <a href="https://automate.video/pages/ai-virtual-presenter">PresenterGPT - AI Virtual Speechwriter</a>
            </li>
            <li className="leading-8">
              <a href="https://automate.video/pages/ai-virtual-presenter">Virtual Presenter</a>
            </li>
            <li className="leading-8">
              <a href="">PowerPoint (PPTX) to video conversion</a>
            </li>
            <li className="leading-8">
              <a href="">PDF to video conversion</a>
            </li>
            <li className="leading-8">
              <a href="https://www.automate.video/blog/how-to-add-automatic-speech-to-powerpoint-ppt">
                Automatic speech
              </a>
            </li>
            <li className="leading-8">
              <a href="https://www.automate.video/blog/how-to-create-an-interactive-video-with-powerpoint-part-1">
                Interactive videos
              </a>
            </li>
            <li className="leading-8">
              <a href="https://www.automate.video/blog/create-interactive-videos">Greenscreen videos</a>
            </li>
          </ul>
        </div>
        <div className="flex-1 mx-6 my-6">
          <h3 className="text-xl font-bold mb-6 ">How to guides</h3>
          <ul>
            <li className="leading-6 mb-2">
              <a href="https://www.automate.video/blog/using-automate-video-and-presentergpt-directly-from-powerpoint">
                Automate.video and PresenterGPT directly from PowerPoint
              </a>
            </li>
            <li className="leading-6 mb-2">
              <a href="https://www.automate.video/blog/presentergpt-tutorial">
                Automatically create a Virtual Presenter with PresenterGPT
              </a>
            </li>

            <li className="leading-6 mb-2">
              <a href="https://www.automate.video/blog/how-to-edit-virtual-presenter-and-automatic-speech">
                How to modify your virtual presenter or automatic speech
              </a>
            </li>
            <li className="leading-6 mb-2">
              <a href="https://www.automate.video/blog/how-to-change-the-voice-of-your-virtual-presenter">
                How to change the voice of your Virtual Presenter
              </a>
            </li>

            <li className="leading-6 mb-2">
              <a href="https://www.automate.video/blog/make-a-speaking-avatar-for-your-bitmoji-classroom-or-explainer">
                Make a speaking avatar for your bitmoji classroom or explainer
              </a>
            </li>
          </ul>
        </div>

        <div className="flex-1 mx-6 my-6">
          <h3 className="text-xl font-bold mb-6 ">About</h3>
          <p className="mb-2">
            Automate.video transforms your PowerPoint (pptx) or PDF presentation to animated video automatically.
          </p>
          <p className="mb-2">Share video easily to your audience.</p>
          <p className="mb-2">
            <a href="https://www.automate.video/home">Read more about automate.video</a>
          </p>
          <p className="mb-2">
            <a href="/terms/privacy-policy.html" target="_blank">
              Privacy Policy
            </a>
          </p>
          <p className="mb-2">
            <a href="/terms/terms-of-service.html" target="_blank">
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
