import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

export default function BasicButton(props) {
  const [tooltip, setTooltip] = React.useState(props.name);
  const [thisStyle, setThisStyle] = React.useState(props.style);

  let style = " bg-blue-dark text-white rounded-md py-1 m-0 hover:bg-blue-dark focus:shadow-outline";
  if (props.normal)
    style =
      " border border-gray-500 bg-blue-dark text-white rounded-md py-3 m-0 font-bold hover:bg-blue-dark focus:shadow-outline";
  if (props.inverted)
    style =
      " border border-gray-500 blue-dark bg-blue-light rounded-md py-2  m-0 font-bold hover:bg-grey focus:shadow-outline";
  if (props.light) style = " text-blue py-1 m-0";
  if (props.gray) style = " text-gray-600 py-1 m-0";
  let onclick = null;
  if (props.onClick) onclick = props.onClick;
  if (props.icon) style += " px-1 ";
  else if (props.normal) style += " px-6 ";
  else style += " px-4 ";

  let exactStyle = {
    transition: "all .15s ease",
  };
  if (props.style) {
    exactStyle = props.style;
  }

  if (props.headerStyle) exactStyle.fontSize = 16;

  if (props.tooltip) {
    return (
      <React.Fragment>
        <a data-tip data-for={tooltip} href={props.url}>
          <button
            type="button"
            onClick={onclick}
            style={thisStyle}
            className={style + "   transition duration-500 ease select-none  focus:outline-none "}
          >
            {props.icon ? <img width="25" src={props.icon} /> : props.name}
          </button>
        </a>
        <ReactTooltip id={tooltip} place="left" type="dark" effect="float">
          {props.tooltip}
        </ReactTooltip>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <a href={props.url}>
          <button
            type="button"
            onClick={onclick}
            style={exactStyle}
            className={style + "   transition duration-500 ease select-none  focus:outline-none "}
          >
            {props.icon ? <img width="25" src={props.icon} /> : props.name}
          </button>
        </a>
      </React.Fragment>
    );
  }
}
