import React from "react";
import BasicButton from "./BasicButton";

import { v4 as uuidV4 } from "uuid";

import styles from "./ShareButton.module.css";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from "react-share";

export default function ShareButton(props) {
  const [copySuccess, setCopySuccess] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState(props.url);
  const [hideScript, setHideScript] = React.useState(true);
  //let shareUrl = props.url;

  React.useEffect(() => {
    if (hideScript) {
      if (props.getStatic) {
        setShareUrl(props.url + "?hideScript=true&getStatic=true");
      } else {
        setShareUrl(props.url + "?hideScript=true");
      }
    } else {
      if (props.getStatic) {
        setShareUrl(props.url + "?getStatic=true");
      } else {
        setShareUrl(props.url);
      }
    }
  }, [hideScript, props.getStatic]);

  let title = props.title;
  let projectid = props.projectid;
  let image = props.image;
  let light = props.light;
  let smallerIcon = false;
  if (props.smallerIcon) smallerIcon = true;

  let data = {
    projectid: projectid,
    fpid: process.browser && window && window.fpid ? window.fpid : null,
    value: 0,
    category: "Video",
    label: "share",
    sessionid: null,
    linkedid: null,
    analyticsid: uuidV4(),
    metadata: {},
    time: new Date(),
  };

  let icon = "/icons/share_icon.png";
  if (props.darkblue) icon = "/icons/share_icon_dark_blue.png";
  return (
    <>
      <BasicButton
        gray
        smallerIcon
        name="Share"
        icon={icon}
        style={{ transition: "all .15s ease" }}
        onClick={() => setShowModal(true)}
        tooltip="Share video"
      ></BasicButton>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-full">
              {/*content*/}
              <div className="border-0 py-4 px-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative gap-1 flex flex-row w-auto flex-wrap ">
                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Facebook",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Facebook";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      className={styles.Demo__some_network__share_button}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Facebook",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Facebook";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <FacebookMessengerShareButton
                      url={shareUrl}
                      appId="333616728056159"
                      className={styles.Demo__some_network__share_button}
                    >
                      <FacebookMessengerIcon size={32} round />
                    </FacebookMessengerShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Twitter",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Twitter";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      className={styles.Demo__some_network__share_button}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Telegram",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Telegram";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <TelegramShareButton
                      url={shareUrl}
                      title={title}
                      className={styles.Demo__some_network__share_button}
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Whatsapp",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Whatsapp";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <WhatsappShareButton
                      url={shareUrl}
                      title={title}
                      separator=":: "
                      className={styles.Demo__some_network__share_button}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      gtag("event", "share", {
                        method: "LinkedIn",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "LinkedIn";
                      window.analyticsEvents.push(data);
                      setShowModal(false);
                    }}
                  >
                    <LinkedinShareButton url={shareUrl} className={styles.Demo__some_network__share_button}>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);

                      gtag("event", "share", {
                        method: "Pinterest",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Pinterest";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <PinterestShareButton
                      url={shareUrl}
                      media={image}
                      className={styles.Demo__some_network__share_button}
                    >
                      <PinterestIcon size={32} round />
                    </PinterestShareButton>
                  </div>

                  <div
                    className={styles.Demo__some_network}
                    onClick={() => {
                      setShowModal(false);
                      gtag("event", "share", {
                        method: "Email",
                        content_type: "video",
                        content_id: projectid,
                      });
                      data.metadata.sharing_method = "Email";
                      window.analyticsEvents.push(data);
                    }}
                  >
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      body="Checkout this video: "
                      className={styles.Demo__some_network__share_button}
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                </div>
                <div className="flex flex-row w-full mb-2">
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-600"
                      checked={hideScript}
                      onChange={() => {}}
                      onClick={() => {
                        setHideScript(!hideScript);
                      }}
                    />
                    <span className="ml-2 text-gray-700">Hide script when sharing</span>
                  </label>
                </div>
                <div className="flex flex-row w-full">
                  <div
                    className="pb-2 mr-6  cursor-pointer text-sm text-gray-500 overflow-hidden overflow-ellipsis"
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl);
                      setCopySuccess("Link copied to clipboard!");
                    }}
                  >
                    {shareUrl}
                  </div>
                  <div
                    className="cursor-pointer text-gray-700 text-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl);
                      setCopySuccess("Link copied to clipboard!");
                    }}
                  >
                    COPY LINK
                  </div>
                </div>
                <div className="flex flex-row w-full">{copySuccess}</div>
                {/*footer*/}
                <div className="flex items-center justify-end pt-1 ">
                  <button
                    className="text-blue background-transparent font-bold uppercase  pt-1 text-sm text-gray-700 outline-none focus:outline-none  mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
